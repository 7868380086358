

































import rService from '@/services/RequestService/RequestServiceSetup';
export default {
  data: () => ({
    entries: [],
    isLoading: false,
    model: '',
    grouped: false,
    search: null,
    loadingAll: false,
  }),

  props: {
    groupedEntry: {
      type: Boolean,
      default: false,
    },
    entry: String,
  },

  methods: {
    async downloadAll() {
      const endPoint = 'my_entries_new_download';
      const newJson = {
        limit: 0,
        page: 0,
        query: '',
      };
      const name = 'leak_check';
      this.loadingAll = true;
      const res = await rService.postFile(endPoint, newJson);
      console.log('Response', res);
      const url = window.URL.createObjectURL(new Blob([res]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'search_' + name + '.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();
      this.loadingAll = false;
    },
    destroy() {
      const Swal = this.$swal;
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!. All your search entries will be cleaned from our database.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(async (result) => {
        if (result.value) {
          Swal.showLoading();
          rService
            .get('clean_entries')
            .then((res) => {})
            .catch((err) => {
              console.log(err);
            })
            .finally(() => (this.isLoading = false));
          Swal.fire('Deleted!', 'All your records have been deleted', 'success');
          window.location.reload();
        }
      });
    },
    newValue(newVal) {
      if (newVal) {
        this.$emit('update:entry', newVal);
      }
    },
    newValueGrouped(newVal) {
      this.$emit('groupedChange', newVal);
    },
    setGroup(group) {
      this.grouped = group;
    },
    reset() {
      this.model = '';
      this.$emit('update:entry', '');
      this.grouped = false;
      this.$emit('groupedChange', false);
    },
  },

  computed: {
    items() {
      return this.entries;
    },
  },

  created() {
    if (this.entry) {
      this.model = this.entry + '';
      this.search = this.model + '';
    }
    this.grouped = this.groupedEntry;
  },

  watch: {
    search(val, oldValue) {
      if (!val) return;
      const valLower = val.toLowerCase();
      const inclusion = val && valLower.includes(oldValue) && this.entries.length < 10;
      if (inclusion && this.entries.length < 50) {
        return;
      }

      if (this.isLoading) return;

      this.isLoading = true;

      rService
        .post('my_entries_search', { search: val })
        .then((res) => {
          this.entries = res;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
